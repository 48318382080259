// extracted by mini-css-extract-plugin
export var aArrow1 = "lc";
export var aArrow2 = "mc";
export var aButton1 = "Wb";
export var aButton2 = "Xb";
export var aButton3 = "Yb";
export var aButton4 = "Zb";
export var aButton5 = "_b";
export var aButtonHeaderLogin = "ac";
export var aButtonHeaderRegister = "bc";
export var aButtonHeaderSpLogin = "ec";
export var aButtonLogin = "dc";
export var aButtonRegister = "cc";
export var aEmpty = "Tb";
export var aTagAnnounce = "ic";
export var aTagClose = "hc";
export var aTagEntry = "fc";
export var aTagFree = "kc";
export var aTagLottery = "jc";
export var aTagPayment = "gc";
export var aText = "nc";
export var aTitle = "Ub";
export var animationMarquee = "oc";
export var pProductsList = "Vb";